import type {
  ApiV2Contents,
  ApiV2CurrentPage,
} from '@dce-front/hodor-types/api/v2/content_grid/definitions';
import type {
  CursorPagination,
  IndexPagination,
} from '@dce-front/hodor-types/modules/pagination/definitions';
import {
  useStore,
  type MiddlewareFactory,
  type StoreBinder,
} from '@dce-front/one-navigation';
import { Ratio, getRatio, isSomeEnum } from '@dce-front/onewebapp-utils';
import { Template, TitleDisplayMode } from '@dce-front/sdk-hodor';
import type { ContentRowHeader } from '@dce-front/sharedcomponent';
import { Placeholder } from '@dce-front/sharedcomponent';
import type { UseInfiniteQueryOptions } from '@tanstack/react-query';
import { useEffect, useMemo, type JSX } from 'react';
import { MetaTagsTemplate } from '../../../components/MetaTags/MetaTagsTemplate';
import { useRoutingContext } from '../../../components/Page/RoutingContext';
import { useIsFrom } from '../../../components/Page/useIsFrom';
import TriggerVerticalScroll from '../../../components/TriggerVerticalScroll/TriggerVerticalScroll';
import { DEFAULT_PLACEHOLDER_NUMBER } from '../../../constants/common';
import useInfiniteQueryTemplate from '../../../helpers/hooks/useInfiniteQueryTemplate/useInfiniteQueryTemplate';
import { useIntersectionObserver } from '../../../helpers/hooks/useIntersectionObserver';
import { useInvariantSelector } from '../../../helpers/hooks/useInvariantSelector';
import { FocusManager } from '../../../helpers/oneNavigation/FocusManager';
import type { FetchDetails } from '../../../services/types';
import { FetchRequestTypes } from '../../../services/types';
import { featIdentityV5Selector } from '../../../store/slices/application-selectors';
import type {
  ContentStrateV5,
  DisplayParameters,
} from '../../../templates/LandingV5/data/formatter';
import { getFormattedContentGridUrl } from '../data/formater';
import { getContentGridNextPageUrl } from '../data/getContentGridNextPageUrl';
import type { ContentGridState } from '../data/types';
import ContentGridError from './ContentGridError';
import ContentGridTemplate from './ContentGridTemplate';

export type ContentGridContainerProps = {
  contents?: ApiV2Contents[] | ContentStrateV5[];
  currentPage?: ApiV2CurrentPage;
  disableMetaUpdate?: boolean;
  displayParameters?: DisplayParameters;
  header?: ContentRowHeader;
  onClickParameters?: FetchDetails['onClickParameters'];
  focusManager?: FocusManager;
  binderId?: StoreBinder['id'];
  paging?: CursorPagination | IndexPagination;
  url?: string;
  isMainTemplate?: boolean;
  middleware?: MiddlewareFactory[];
  isVirtualization?: boolean;
  offsetTop?: number;
};

function ContentGridContainer({
  contents,
  currentPage,
  disableMetaUpdate = false,
  displayParameters: {
    imageRatio = Ratio.Ratio169,
    imageSize = 'normal',
    titleDisplayMode = TitleDisplayMode.All,
  } = {},
  header,
  onClickParameters,
  focusManager,
  binderId,
  paging,
  url,
  isMainTemplate,
  middleware,
  isVirtualization = false,
  offsetTop,
}: ContentGridContainerProps): JSX.Element {
  const routingContext = useRoutingContext();
  const isImmersive = routingContext === 'immersive';
  const featIdentityV5 = useInvariantSelector(featIdentityV5Selector);
  const isFromDetail = useIsFrom(Template.DetailPage);
  const isFromGabaritList = useIsFrom(Template.GabaritList);

  const reactQueryOptions = useMemo(
    () => ({
      // if we have contents, do not trigger react-query and use initial data by default
      initialData: contents?.length
        ? {
            pages: [
              {
                contents,
                paging,
                currentPage,
              },
            ],
            pageParams: [],
          }
        : undefined,

      enabled: !contents?.length && !!url,
      getNextPageParam: (lastPageData) =>
        getContentGridNextPageUrl(lastPageData),
      // @TODO tv device : cache is set to 0 here to avoid focus lost in the following scenario :
      // - open content grid via more info
      // - open an immersive
      // - open another content grid via more info
      // - back 2 times (to get the first content grid)
      // - focus lost
      // This is due to the fact that when going back, the focus is reset to page layer before
      // this component updates its DOM from react-query cache. Forcing a query refetch will
      // unregister / register the binder and make the back scenario work. This is not ideal and
      // should be removed once we find a proper way update the binder when a focused element is
      // removed from the DOM

      ...($_BUILD_RENDERMODE_CSR ? { cgTime: 0, staleTime: 0 } : {}),
    }),
    [contents, currentPage, paging, url],
  ) satisfies Partial<UseInfiniteQueryOptions<ContentGridState>>;

  const [
    {
      isLoading,
      isError,
      isSuccess,
      fetchNextPage,
      hasNextPage,
      isFetchingNextPage,
      data,
    },
  ] = useInfiniteQueryTemplate<ContentGridState>(
    getFormattedContentGridUrl({ url, isFromGabaritList }),
    {
      onClickParameters,
      template: FetchRequestTypes.ContentGrid,
      isMainTemplate,
    },
    reactQueryOptions,
    { featIdentityV5 },
  );

  /**
   * call onFocusable when receives new data and immersive display state changes
   */
  const store = useStore();
  useEffect(() => {
    if (focusManager && isSuccess) {
      // FIXME: why it has to focus undefined ?
      store.getActiveLayer().focus(undefined);
      focusManager.onFocusable();
    }
  }, [focusManager, isSuccess, store, isImmersive]);

  const { refCallback } = useIntersectionObserver({
    isEnabled: hasNextPage && !isFetchingNextPage,
    onIntersect: fetchNextPage,
  });

  const {
    imageSize: pageImageSize,
    imageRatio: pageImageRatio,
    titleDisplayMode: pageTitleDisplayMode,
  } = data?.pages?.[0]?.displayParameters || {};

  const contentGridImageSize = pageImageSize || imageSize;
  const contentGridImageRatio = getRatio({
    imageRatio: pageImageRatio,
    defaultValue: getRatio({ imageRatio }),
  });
  const contentGridTitleDisplayMode =
    pageTitleDisplayMode && isSomeEnum(TitleDisplayMode)(pageTitleDisplayMode)
      ? (pageTitleDisplayMode as TitleDisplayMode)
      : titleDisplayMode;

  const hasContents = !!data?.pages?.[0]?.contents?.length;
  if (isError || (!isLoading && !hasContents)) {
    return <ContentGridError />;
  }

  return (
    <section>
      {isLoading ? (
        <Placeholder
          imageSize={contentGridImageSize}
          isFromDetail={isFromDetail}
          nbPlaceholder={DEFAULT_PLACEHOLDER_NUMBER}
          ratio={contentGridImageRatio}
        />
      ) : (
        <>
          <MetaTagsTemplate
            data={data}
            disableMetaUpdate={disableMetaUpdate}
            enableAlternateLinksUpdate
          />
          <ContentGridTemplate
            key={url}
            data={data}
            header={header}
            imageRatio={contentGridImageRatio}
            imageSize={contentGridImageSize}
            titleDisplayMode={contentGridTitleDisplayMode}
            isFetchingNextPage={isFetchingNextPage}
            focusManager={focusManager}
            binderId={binderId}
            middleware={middleware}
            isVirtualization={isVirtualization}
            offsetTop={offsetTop}
            isMainTemplate={isMainTemplate}
          />
        </>
      )}
      <TriggerVerticalScroll ref={refCallback} />
    </section>
  );
}

export default ContentGridContainer;
